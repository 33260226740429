import {
  Button,
  Input,
  Flex,
  Heading,
  Grid,
  GridItem,
  Text,
  // Select,
  useToast,
} from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
// import { useEffect, useState, useCallback } from "react";
import { useState } from "react";
import useSupplyChain from "../../../hooks/useSupplyChain";

const FormDistributor = () => {
  const { library, account } = useWeb3React();
  const [itemIDReceive, setItemIDReceive] = useState("");
  const [retailerNameReg, setRetailerNameReg] = useState("");
  const [retailerLocReg, setRetailerLocReg] = useState("");
  const [txItemReg, setTxItemReg] = useState(false);
  const [itemIDPack, setItemIDPack] = useState("");
  const [itemIDShip, setItemIDShip] = useState("");
  const supplyChain = useSupplyChain();
  const toast = useToast();

  const receiveItem = async () => {
    if (
      itemIDReceive === "" ||
      retailerNameReg === "" ||
      retailerLocReg === ""
    ) {
      toast({
        title: "Recieve Item Registration - Error",
        description: "Verify data",
        status: "error",
      });
    } else {
      setTxItemReg(true);
      supplyChain.methods
        .receiveItemByDistributor(
          library.utils.padRight(library.utils.utf8ToHex(itemIDReceive), 64),
          library.utils.padRight(library.utils.utf8ToHex(retailerNameReg), 64),
          library.utils.padRight(library.utils.utf8ToHex(retailerLocReg), 64)
        )
        .send({ from: account })
        .on("error", () => {
          setTxItemReg(false);
          toast({
            title: "Recieve Item Registration - Error",
            description: "Error during registration process",
            status: "error",
          });
        })
        .on("transactionHash", (txHash) => {
          toast({
            title: "Recieve Item Registration - Transaction sent",
            description: txHash,
            status: "info",
          });
        })
        .on("receipt", () => {
          setTxItemReg(false);
          toast({
            title: "Recieve Item Registration - Transaction validated",
            description: `The Item ${itemIDReceive} was successfully received by a distributor`,
            status: "success",
          });
        });
    }
  };

  const packItem = async () => {
    if (itemIDPack === "") {
      toast({
        title: "Pack Item Registration - Error",
        description: "Verify data",
        status: "error",
      });
    } else {
      setTxItemReg(true);
      supplyChain.methods
        .packItemByDistributor(
          library.utils.padRight(library.utils.utf8ToHex(itemIDPack), 64)
        )
        .send({ from: account })
        .on("error", () => {
          setTxItemReg(false);
          toast({
            title: "Pack Item Registration - Error",
            description: "Error during registration process",
            status: "error",
          });
        })
        .on("transactionHash", (txHash) => {
          toast({
            title: "Pack Item Registration - Transaction sent",
            description: txHash,
            status: "info",
          });
        })
        .on("receipt", () => {
          setTxItemReg(false);
          toast({
            title: "Pack Item Registration - Transaction validated",
            description: `The Item ${itemIDPack} was successfully packed by a distributor`,
            status: "success",
          });
        });
    }
  };

  const shipItem = async () => {
    if (itemIDShip === "") {
      toast({
        title: "Ship Item Registration - Error",
        description: "Verify data",
        status: "error",
      });
    } else {
      setTxItemReg(true);
      supplyChain.methods
        .shipItemByDistributor(
          library.utils.padRight(library.utils.utf8ToHex(itemIDShip), 64)
        )
        .send({ from: account })
        .on("error", () => {
          setTxItemReg(false);
          toast({
            title: "Ship Item Registration - Error",
            description: "Error during registration process",
            status: "error",
          });
        })
        .on("transactionHash", (txHash) => {
          toast({
            title: "Ship Item Registration - Transaction sent",
            description: txHash,
            status: "info",
          });
        })
        .on("receipt", () => {
          setTxItemReg(false);
          toast({
            title: "Ship Item Registration - Transaction validated",
            description: `The Item ${itemIDShip} was successfully shipped by a distributor`,
            status: "success",
          });
        });
    }
  };

  return (
    <Flex
      flexDirection="column"
      alignItems={"center"}
      justifyContent="center"
      minWidth="80%"
    >
      {/* Receive Item form */}
      <Flex
        flexDirection="column"
        minWidth="100%"
        alignItems="center"
        gap="3"
        mt="40px"
        mb="60px"
      >
        <Heading size="md" mb="20px" minWidth="100%">
          RECEIVE ITEM
        </Heading>
        <Grid
          minWidth={"80%"}
          templateColumns={["90%", "100px 300px"]}
          gap={6}
          justifyContent="center"
          alignItems="center"
        >
          <GridItem rowSpan="1" colSpan="1">
            <Text>ID:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Item ID"
              size="md"
              bg="white"
              value={itemIDReceive}
              onChange={(event) => setItemIDReceive(event.target.value)}
            />
          </GridItem>

          <GridItem rowSpan="1" colSpan="1">
            <Text>Name:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Distributor name"
              size="md"
              bg="white"
              value={retailerNameReg}
              onChange={(event) => setRetailerNameReg(event.target.value)}
            />
          </GridItem>

          <GridItem rowSpan="1" colSpan="1">
            <Text>Location:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Location name"
              size="md"
              bg="white"
              value={retailerLocReg}
              onChange={(event) => setRetailerLocReg(event.target.value)}
            />
          </GridItem>

          <GridItem
            rowSpan="1"
            colSpan="2"
            justifyContent="center"
            alignItems="center"
            minWidth="100%"
            margin="auto"
          >
            <Flex justifyContent="center" alignItems="center">
              <Button
                w={["100%", "100px"]}
                colorScheme="blue"
                size="md"
                type="submit"
                onClick={() => receiveItem()}
                isLoading={txItemReg}
              >
                Register
              </Button>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>

      {/* Pack Item form */}
      <Flex
        flexDirection="column"
        minWidth="100%"
        alignItems="center"
        gap="3"
        mt="40px"
        mb="60px"
      >
        <Heading size="md" mb="20px" minWidth="100%">
          PACK ITEM
        </Heading>
        <Grid
          minWidth={"80%"}
          templateColumns={["90%", "100px 300px"]}
          gap={6}
          justifyContent="center"
          alignItems="center"
        >
          <GridItem rowSpan="1" colSpan="1">
            <Text>ID:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Item ID"
              size="md"
              bg="white"
              value={itemIDPack}
              onChange={(event) => setItemIDPack(event.target.value)}
            />
          </GridItem>
          <GridItem
            rowSpan="1"
            colSpan="2"
            justifyContent="center"
            alignItems="center"
            minWidth="100%"
            margin="auto"
          >
            <Flex justifyContent="center" alignItems="center">
              <Button
                w={["100%", "100px"]}
                colorScheme="blue"
                size="md"
                type="submit"
                onClick={() => packItem()}
                isLoading={txItemReg}
              >
                Register
              </Button>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
      {/* Ship Item form */}
      <Flex
        flexDirection="column"
        minWidth="100%"
        alignItems="center"
        gap="3"
        mt="40px"
        mb="60px"
      >
        <Heading size="md" mb="20px" minWidth="100%">
          SHIP ITEM
        </Heading>
        <Grid
          minWidth={"80%"}
          templateColumns={["90%", "100px 300px"]}
          gap={6}
          justifyContent="center"
          alignItems="center"
        >
          <GridItem rowSpan="1" colSpan="1">
            <Text>ID:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Item ID"
              size="md"
              bg="white"
              value={itemIDShip}
              onChange={(event) => setItemIDShip(event.target.value)}
            />
          </GridItem>
          <GridItem
            rowSpan="1"
            colSpan="2"
            justifyContent="center"
            alignItems="center"
            minWidth="100%"
            margin="auto"
          >
            <Flex justifyContent="center" alignItems="center">
              <Button
                w={["100%", "100px"]}
                colorScheme="blue"
                size="md"
                type="submit"
                onClick={() => shipItem()}
                isLoading={txItemReg}
              >
                Register
              </Button>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default FormDistributor;
