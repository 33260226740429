import React from 'react';
import { 
  Flex, 
  Button, 
  Input, 
  Spacer,
  Box,
  //Container,
  //Stack,
  Image, 
  HStack, 
  VStack,
  IconButton,
  useBreakpointValue,
  Stack,
  Heading,
  Text,
  Container,
  Center, 
} from "@chakra-ui/react";
import { createRoutesFromElements, Link } from "react-router-dom";
import { useCallbacks, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
//import CaptionCarousel from "./carrusel.js";

// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
// And react-slick as our Carousel Lib
import Slider from 'react-slick';

// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Home = () => {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState(null); // deleted <> React.useState<Slider | null>(null)

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: '90%', md: '50%' });
  const side = useBreakpointValue({ base: '30%', md: '40px' });

  // This list contains all the data for carousels
  // This can be static or loaded from a server
  const cards = [
    {
      title: 'The Problem',
      text:
        <text>
          Farmers deal mostly on a trial-and-error basis, with seasonality and climate changes that impact their production yield. 
          Operation costs including managing water resources and agrochemicals make small farmers less competitive comparing to large 
          producers that have capabilities to build infrastructure to address such problems. There is not a techno-economical feasible 
          automated solution for decision making process for small and mid farmers. In addition, the lack of data regarding crops limits 
          ensuring their origin and quality, constraining traceability for the end product. Hence, labelling process are not reliable enough 
          to ensure health and quality, which is vital for food safety.
        </text>,
      image:
        //'https://images.unsplash.com/photo-1516796181074-bf453fbfa3e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60',
        "./logos/problem1.jpg",
    },
    {
      title: 'Our solution',
      text:
        <text>
          We integrates two technologies. In one hand, Precision Agriculture PA, which enables to gather remote, periodic and precise information 
          about crops, to enhance its performance in the long term, reduce operating costs and consumables, and contribute for decision making process. 
          The other technology we implement in our framework is Blockchain, which uses the data collected and processed by PA, to guarantee origin on the crops, 
          protocols using in farming, among others. The immutable and open nature of blockchain enables to farmers, customers, suppliers, and shippers to verify 
          product traceability and ensure quality standards.
        </text>,
      image:
        //'https://images.unsplash.com/photo-1438183972690-6d4658e3290e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2274&q=80',
        "./logos/photo2.jpg",
    },
    {
      title: 'Work that has been done',
      text:
        <text>
          Our Latvian start-up company Golden Sparrow Technology and Blockchain Development Latvia SIA is developing our Technified Food Supply Chain TFSC
          application to combine Precision Agriculture PA (monitoring and data processing from satellite images and UAVs in different spectrums) 
          and Blockchain (as an oracle solution for delivering reliable information on crops) for assisting small and middle farmers on improving their production.
        </text>,
      image:
        //'https://images.unsplash.com/photo-1507237998874-b4d52d1dd655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60',
        "./logos/farming3.jpg",
    },
  ];

  const { active } = useWeb3React();
  let disableBtn = true;
  if (active) disableBtn = false;
  return (
    <VStack
      w="100%"
      //bgImage="url('https://images.pexels.com/photos/106162/wheat-wheat-spike-wheat-field-cornfield-106162.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')"
      //bgSize="cover"
      bgPosition={"center"}
      h="full"
      alignItems={"center"}
      justifyContent="center"
      m="0px"
      p="0px"
      spacing={4}
      align='stretch'
    >
      <Box
        position={'static'}
        height={'100vh'}
        width={'full'}
        overflow={'hidden'}
        //borderColor="green"  border={"solid"}
        marginTop="-2"
      >
        {/* CSS files for react-slick */}
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        {/* Left Icon */}
        <IconButton
          //aria-label="left-arrow"
          variant="ghost"
          position="absolute"
          size="mt"
          left={side}
          top="90vh"//{top}
          transform={'translate(0%, -50%)'}
          zIndex={2}
          onClick={() => slider?.slickPrev()}>
          <BiLeftArrowAlt size="40px" />
        </IconButton>
        {/* Right Icon */}
        <IconButton
          aria-label="right-arrow"
          variant="ghost"
          position="absolute"
          align="right"
          right={side}
          top="90vh"//{top}
          transform={'translate(0%, -50%)'}
          zIndex={2}
          onClick={() => slider?.slickNext()}>
          <BiRightArrowAlt size="40px" />
        </IconButton>
        {/* Slider */}
        <Slider {...settings} ref={(slider) => setSlider(slider)}>
          {cards.map((card, index) => (
            <Box
              key={index}
              height={'100vh'}
              position="relative"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              backgroundImage={`url(${card.image})`}
              //border='solid' borderColor={'green'} zIndex='999'
              >
              {/* This is the block you need to change, to customize the caption */}
              <Container size="container.lg" height="600px" maxW='container.md' position="relative"  
                //border='solid' borderColor={'green'} zIndex='999'
                >
                <Stack
                  spacing={6}
                  w={'100%'}
                  maxW={'container.md'}
                  position="absolute"
                  top="20%"
                  //border='solid' borderColor={'green'} zIndex='999'
                  //bgColor={"rgba(255,255,255,0.5)"}
                  bgGradient="linear(to-b,rgba(255,255,255,0.7), rgba(240, 164, 89,0.4))"
                  borderRadius="10"
                  >
                  <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }} w={'90%'} paddingLeft="10" paddingTop="10">
                    {card.title}
                  </Heading>
                  <Text fontSize={{ base: 'md', md: 'sm', lg: 'lg' }} w={'95%'} align='left' color="White" paddingLeft="10" paddingBottom="10">
                    {card.text}
                  </Text>
                </Stack>
              </Container>
            </Box>
          ))}
        </Slider>  
      </Box>
      <VStack
        w="full" spacing={"p0x"}>
        <Box 
          border="5px"
          borderStyle={"Solid"}
          borderColor={"white white #bb923c white"}
          width="90%"
          >
          <Heading
          w={'90%'}
          align="left">
            TRACKING STEPS
          </Heading>
        </Box>
        <Box height={"10px"}></Box>
        <Box
          flexDirection={["column", "row"]} 
          maxWidth="90%"
          position="relative"
          width={"90%"}
          h="50vh" 
          spacing="0px" 
          justifyContent="center"
          alignItems={"center"}
          >
          <HStack spacing="p0x" >
            <Box
              alignItems={"center"}
              alignContent="center"
              display={"md"}
              w="50%"
              height="50vh"
              //border='solid' borderColor={'green'}
            >
              <Center>
                <VStack>
                  <Heading as="h1" size="lg" paddingBottom={"20px"} paddingTop="30px"> STEP 1</Heading>
                  <Image src="./logos/Imagen9.png" align="center" position="relative" paddingBottom={"20px"}></Image>
                  <Text>Connect your wallet.</Text>
                </VStack>
              </Center>
            </Box>
            <Box
              w="50%"
              height="50vh"
            >
              <Image src="./logos/1s1.gif" width={"100%"} height="100%" position="relative" objectFit="cover" ></Image>
            </Box>
          </HStack>
        </Box>
        <Box
          flexDirection={["column", "row"]} 
          maxWidth="90%"
          position="relative"
          width={"90%"}
          h="50vh" 
          spacing="0px" 
          justifyContent="center"
          alignItems={"center"}
          >
          <HStack spacing={"p0x"}>
            <Box
              alignItems={"center"}
              alignContent="center"
              display={"md"}
              w="50%"
              height="50vh"
            >
              <Image src="./logos/1s2.gif" width={"100%"} height="100%" position="relative" objectFit="cover"/>
            </Box>
            <VStack
              alignContent="center"
              w="50%"
              height="50vh"
            >
              <Heading as="h1" size="lg" paddingBottom={"20px"} paddingTop="30px"> STEP 2</Heading>
              <Image src="./logos/Imagen4.png" align="center" position="relative" paddingBottom={"20px"}></Image>
              <Text>Goto tracking window and paste your tracking code.</Text>
            </VStack>
          </HStack>
        </Box>
        <Box
          flexDirection={["column", "row"]} 
          maxWidth="90%"
          position="relative"
          width={"90%"}
          h="50vh" 
          spacing="0px" 
          justifyContent="center"
          alignItems={"center"}
          >
          <HStack spacing={"p0x"}>
            <VStack
              alignContent="center"
              w="50%"
              height="50vh"
            >
              <Heading as="h1" size="lg" paddingBottom={"20px"} paddingTop="30px"> STEP 3</Heading>
              <Image src="./logos/Imagen12.png" align="center" position="relative" paddingBottom={"20px"}></Image>
              <Text>All information about tracking code required are showing up.</Text>
            </VStack>
            <Box
              alignItems={"center"}
              alignContent="center"
              display={"md"}
              w="50%"
              height="50vh"
            >
              <Image src="./logos/1s3.gif" align="center" position="relative" objectFit="fill" boxSize="full"/>
            </Box>
          </HStack>
        </Box>
      </VStack>

      <VStack
        w="full" spacing={"p0x"}>
        <Box 
          border="5px"
          borderStyle={"Solid"}
          borderColor={"white white #bb923c white"}
          width="90%">
          <Heading
            w={'90%'}
            align="left">
            LOGGING AND UPLOADING STEPS
          </Heading>
        </Box>
        <Box height={"10px"}></Box>
        <Box
          flexDirection={["column", "row"]} 
          maxWidth="90%"
          position="relative"
          width={"90%"}
          h="50vh" 
          spacing="0px" 
          justifyContent="center"
          alignItems={"center"}
          >
          <HStack spacing={"p0x"}>
            <VStack
              alignContent="center"
              w="50%"
              height="50vh"
            >
              <Heading as="h1" size="lg" paddingBottom={"20px"} paddingTop="30px"> STEP 1</Heading>
              <Image src="./logos/Imagen9.png" align="center" position="relative" paddingBottom={"20px"}></Image>
              <Text>Connect your wallet and check the profile status.</Text>
            </VStack>
            <Box
              alignItems={"center"}
              alignContent="center"
              display={"md"}
              w="50%"
              height="50vh"
            >
              <Image src="./logos/2s1.gif" align="center" position="relative" objectFit="fill" boxSize="full"/>
            </Box>
          </HStack>
        </Box>
        <Box
          flexDirection={["column", "row"]} 
          maxWidth="90%"
          position="relative"
          width={"90%"}
          h="50vh" 
          spacing="p0x" 
          justifyContent="center"
          alignItems={"center"}
          >
          <HStack spacing={"p0x"}>
            <Box
              alignItems={"center"}
              alignContent="center"
              display={"md"}
              w="50%"
              height="50vh"
            >
              <Image src="./logos/2s2.gif" width={"100%"} height="100%" position="relative" objectFit="cover"/>
            </Box>
            <VStack
              alignContent="center"
              w="50%"
              height="50vh"             
            >
              <Heading as="h1" size="lg" paddingBottom={"20px"} paddingTop="30px"> STEP 2</Heading>
              <Image src="./logos/Imagen11.png" align="center" position="relative" paddingBottom={"20px"}></Image>
              <Text>Upload the information required by the profile type.</Text>
            </VStack>
          </HStack>
        </Box>
        <Box
          flexDirection={["column", "row"]} 
          maxWidth="90%"
          position="relative"
          width={"90%"}
          h="50vh" 
          spacing="0px" 
          justifyContent="center"
          alignItems={"center"}
          >
          <HStack spacing={"p0x"}>
            <VStack
              alignContent="center"
              w="50%"
              height="50vh"
            >
              <Heading as="h1" size="lg" paddingBottom={"20px"} paddingTop="30px"> STEP 3</Heading>
              <Image src="./logos/Imagen10.png" align="center" position="relative" paddingBottom={"20px"}></Image>
              <Text>Sign the transaction.</Text>
            </VStack>
            <Box
              alignItems={"center"}
              alignContent="center"
              display={"md"}
              w="50%"
              height="50vh"
            >
              <Image src="./logos/2s3.gif" width={"100%"} height="100%" position="relative" objectFit="cover"/>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </VStack>
  );
};

export default Home;
