import {
  Button,
  Input,
  Flex,
  Heading,
  Grid,
  GridItem,
  Text,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState, useCallback } from "react";
import useSupplyChain from "../../../hooks/useSupplyChain";

const FormDataManager = () => {
  const { library, account } = useWeb3React();
  const supplyChain = useSupplyChain();
  const toast = useToast();
  const [farmID, setFarmID] = useState("");
  const [farmList, setFarmList] = useState([]);
  const [txItemReg, setTxItemReg] = useState(false);
  const [farmIdxNDVI, setFarmIdxNDVI] = useState("");
  const [farmIdxGCI, setFarmIdxGCI] = useState("");
  const [farmIdxNDWI, setFarmIdxNDWI] = useState("");
  const [farmIdxINFR, setFarmIdxINFR] = useState("");
  const [farmIpfsCID, setFarmIpfsCID] = useState("");
  const [farmIpfsImgFile, setFarmIpfsImgFile] = useState("");

  const getFarmList = useCallback(async () => {
    const list = await supplyChain.methods.getFarmsArr().call();
    let newList = [];
    list.map((element) => newList.push(library.utils.hexToUtf8(element)));
    setFarmList(newList);
  }, [supplyChain, library?.utils]);

  // const getFarmName = async (farmID) => {
  //   let data = await supplyChain.methods
  //     .getFarmData(library.utils.utf8ToHex(farmID))
  //     .call();
  //   // setItemFarmNameReg(library.utils.hexToUtf8(data.farmName));
  // };

  useEffect(() => {
    getFarmList();
  }, [getFarmList]);

  const updateFarmData = async () => {
    if (
      farmID === "" ||
      farmIdxNDVI === "" ||
      farmIdxGCI === "" ||
      farmIdxNDWI === "" ||
      farmIdxINFR === ""
    ) {
      toast({
        title: "Update Index Farm Data - Error",
        description: "Verify data",
        status: "error",
      });
    } else {
      setTxItemReg(true);
      supplyChain.methods
        .registerIdxs(
          library.utils.padRight(library.utils.utf8ToHex(farmID), 64),
          farmIdxNDVI,
          farmIdxGCI,
          farmIdxNDWI,
          farmIdxINFR
        )
        .send({ from: account })
        .on("error", () => {
          setTxItemReg(false);
          toast({
            title: "Update Index Farm Data - Error",
            description: "Error during data update",
            status: "error",
          });
        })
        .on("transactionHash", (txHash) => {
          toast({
            title: "Update Index Farm Data - Transaction sent",
            description: txHash,
            status: "info",
          });
        })
        .on("receipt", () => {
          setTxItemReg(false);
          toast({
            title: "Update Index Farm Data - Transaction validated",
            description: `The Farm ${farmID} Data was successfully updated`,
            status: "success",
          });
        });
    }
  };

  const registerIpfs = async () => {
    if (farmID === "" || farmIpfsCID === "" || farmIpfsImgFile === "") {
      toast({
        title: "Update IPFS Farm Data - Error",
        description: "Verify data",
        status: "error",
      });
    } else {
      setTxItemReg(true);
      supplyChain.methods
        .registerIpfs(
          library.utils.padRight(library.utils.utf8ToHex(farmID), 64),
          farmIpfsCID,
          farmIpfsImgFile
        )
        .send({ from: account })
        .on("error", () => {
          setTxItemReg(false);
          toast({
            title: "Update IPFS Farm Data - Error",
            description: "Error during data update",
            status: "error",
          });
        })
        .on("transactionHash", (txHash) => {
          toast({
            title: "Update IPFS Farm Data - Transaction sent",
            description: txHash,
            status: "info",
          });
        })
        .on("receipt", () => {
          setTxItemReg(false);
          toast({
            title: "Update IPFS Farm Data - Transaction validated",
            description: `The IPFS Farm ${farmID} Data was successfully updated`,
            status: "success",
          });
        });
    }
  };

  return (
    <Flex
      flexDirection="column"
      alignItems={"center"}
      justifyContent="center"
      minWidth="80%"
    >
      {/* Update Farm Data form */}
      <Flex
        flexDirection="column"
        minWidth="100%"
        alignItems="center"
        gap="3"
        mt="40px"
        mb="60px"
      >
        <Heading size="md" mb="20px" minWidth="100%">
          INDEX REGISTRATION
        </Heading>
        <Grid
          minWidth={"80%"}
          templateColumns={["90%", "100px 300px"]}
          gap={6}
          justifyContent="center"
          alignItems="center"
        >
          <GridItem rowSpan="1" colSpan="1">
            <Text>Farm ID:</Text>
          </GridItem>
          <Select
            placeholder="Select Farm ID"
            onChange={(event) => {
              setFarmID(event.target.value);
              // getFarmName(event.target.value);
            }}
          >
            {farmList.map((farm) => (
              <option value={farm} key={farm}>
                {farm}
              </option>
            ))}
          </Select>

          <GridItem rowSpan="1" colSpan="1">
            <Text>NDVI:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="NDVI Index (%)"
              size="md"
              bg="white"
              value={farmIdxNDVI}
              onChange={(event) => setFarmIdxNDVI(event.target.value)}
            />
          </GridItem>

          <GridItem rowSpan="1" colSpan="1">
            <Text>GCI:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="CGI Index (%)"
              size="md"
              bg="white"
              value={farmIdxGCI}
              onChange={(event) => setFarmIdxGCI(event.target.value)}
            />
          </GridItem>

          <GridItem rowSpan="1" colSpan="1">
            <Text>NDWI:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="NDWI Index (%)"
              size="md"
              bg="white"
              value={farmIdxNDWI}
              onChange={(event) => setFarmIdxNDWI(event.target.value)}
            />
          </GridItem>

          <GridItem rowSpan="1" colSpan="1">
            <Text>INFR:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Infrared Index"
              size="md"
              bg="white"
              value={farmIdxINFR}
              onChange={(event) => setFarmIdxINFR(event.target.value)}
            />
          </GridItem>

          <GridItem
            rowSpan="1"
            colSpan="2"
            justifyContent="center"
            alignItems="center"
            minWidth="100%"
            margin="auto"
          >
            <Flex justifyContent="center" alignItems="center">
              <Button
                w={["100%", "100px"]}
                colorScheme="blue"
                size="md"
                type="submit"
                onClick={() => updateFarmData()}
                isLoading={txItemReg}
              >
                Register
              </Button>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
      {/* Update IPFS Data form */}
      <Flex
        flexDirection="column"
        minWidth="100%"
        alignItems="center"
        gap="3"
        mt="40px"
        mb="60px"
      >
        <Heading size="md" mb="20px" minWidth="100%">
          IPFS DATA REGISTRATION
        </Heading>
        <Grid
          minWidth={"80%"}
          templateColumns={["90%", "100px 300px"]}
          gap={6}
          justifyContent="center"
          alignItems="center"
        >
          <GridItem rowSpan="1" colSpan="1">
            <Text>Farm ID:</Text>
          </GridItem>
          <Select
            placeholder="Select Farm ID"
            onChange={(event) => {
              setFarmID(event.target.value);
              // getFarmName(event.target.value);
            }}
          >
            {farmList.map((farm) => (
              <option value={farm} key={farm}>
                {farm}
              </option>
            ))}
          </Select>

          <GridItem rowSpan="1" colSpan="1">
            <Text>IPFS CID:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="IPFS Content Identifier (CID)"
              size="md"
              bg="white"
              value={farmIpfsCID}
              onChange={(event) => setFarmIpfsCID(event.target.value)}
            />
          </GridItem>

          <GridItem rowSpan="1" colSpan="1">
            <Text>Image Filename:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Filename.jpg"
              size="md"
              bg="white"
              value={farmIpfsImgFile}
              onChange={(event) => setFarmIpfsImgFile(event.target.value)}
            />
          </GridItem>

          <GridItem
            rowSpan="1"
            colSpan="2"
            justifyContent="center"
            alignItems="center"
            minWidth="100%"
            margin="auto"
          >
            <Flex justifyContent="center" alignItems="center">
              <Button
                w={["100%", "100px"]}
                colorScheme="blue"
                size="md"
                type="submit"
                onClick={() => registerIpfs()}
                isLoading={txItemReg}
              >
                Register
              </Button>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default FormDataManager;
