import { extendTheme } from "@chakra-ui/react";

const MyTheme = extendTheme({
  styles: {
    global: () => ({
      body: {
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#888",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      },
    }),
  },
});

export default MyTheme;
