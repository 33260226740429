const SupplyChainArtifact = {
  //address: { 80001: "0x54B10DdBb211e19806Df751e4482BbC3fFF09c17" },
  address: { 80001: "0x4Bc2CC3c5EE4449568A6C46dB94461dA41553e0D" },
  abi: [
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_addr",
          type: "address",
        },
      ],
      name: "getAddrRole",
      outputs: [
        {
          internalType: "enum SupplyChain.AddressRole",
          name: "",
          type: "uint8",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getAddrsList",
      outputs: [
        {
          internalType: "address[]",
          name: "",
          type: "address[]",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_farmID",
          type: "bytes32",
        },
      ],
      name: "getFarmData",
      outputs: [
        {
          components: [
            {
              internalType: "bytes32",
              name: "farmName",
              type: "bytes32",
            },
            {
              internalType: "int32",
              name: "farmArea",
              type: "int32",
            },
            {
              internalType: "int32",
              name: "farmLat",
              type: "int32",
            },
            {
              internalType: "int32",
              name: "farmLon",
              type: "int32",
            },
            {
              internalType: "string",
              name: "farmIpfsCID",
              type: "string",
            },
            {
              internalType: "string",
              name: "farmImgFile",
              type: "string",
            },
            {
              internalType: "int32",
              name: "ndvi",
              type: "int32",
            },
            {
              internalType: "int32",
              name: "gci",
              type: "int32",
            },
            {
              internalType: "int32",
              name: "ndwi",
              type: "int32",
            },
            {
              internalType: "int32",
              name: "infr",
              type: "int32",
            },
            {
              internalType: "bool",
              name: "reg",
              type: "bool",
            },
          ],
          internalType: "struct SupplyChain.Farm",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getFarmsArr",
      outputs: [
        {
          internalType: "bytes32[]",
          name: "",
          type: "bytes32[]",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_itemID",
          type: "bytes32",
        },
      ],
      name: "getItemData",
      outputs: [
        {
          internalType: "bytes32",
          name: "itemName",
          type: "bytes32",
        },
        {
          internalType: "bytes32",
          name: "description",
          type: "bytes32",
        },
        {
          internalType: "bytes32",
          name: "orgnFarmID",
          type: "bytes32",
        },
        {
          internalType: "bytes32",
          name: "orgnFarmName",
          type: "bytes32",
        },
        {
          internalType: "address",
          name: "farmerAcc",
          type: "address",
        },
        {
          components: [
            {
              internalType: "bytes32",
              name: "name",
              type: "bytes32",
            },
            {
              internalType: "bytes32",
              name: "location",
              type: "bytes32",
            },
            {
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          internalType: "struct SupplyChain.DataAccounts",
          name: "dataDistributor",
          type: "tuple",
        },
        {
          components: [
            {
              internalType: "bytes32",
              name: "name",
              type: "bytes32",
            },
            {
              internalType: "bytes32",
              name: "location",
              type: "bytes32",
            },
            {
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          internalType: "struct SupplyChain.DataAccounts",
          name: "dataRetailer",
          type: "tuple",
        },
        {
          internalType: "enum SupplyChain.State",
          name: "state",
          type: "uint8",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getItemsID",
      outputs: [
        {
          internalType: "bytes32[]",
          name: "",
          type: "bytes32[]",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_itemID",
          type: "bytes32",
        },
        {
          internalType: "bytes32",
          name: "_itemName",
          type: "bytes32",
        },
        {
          internalType: "bytes32",
          name: "_description",
          type: "bytes32",
        },
        {
          internalType: "bytes32",
          name: "_orgnFarmID",
          type: "bytes32",
        },
        {
          internalType: "bytes32",
          name: "_orgnFarmName",
          type: "bytes32",
        },
      ],
      name: "harvestItemByFarmer",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_itemID",
          type: "bytes32",
        },
      ],
      name: "packItemByDistributor",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_itemID",
          type: "bytes32",
        },
      ],
      name: "packItemByFarmer",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_itemID",
          type: "bytes32",
        },
      ],
      name: "processItemByFarmer",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_itemID",
          type: "bytes32",
        },
      ],
      name: "processItemByRetailer",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_itemID",
          type: "bytes32",
        },
        {
          internalType: "bytes32",
          name: "_distributorName",
          type: "bytes32",
        },
        {
          internalType: "bytes32",
          name: "_distributorLocation",
          type: "bytes32",
        },
      ],
      name: "receiveItemByDistributor",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_itemID",
          type: "bytes32",
        },
        {
          internalType: "bytes32",
          name: "_retailerName",
          type: "bytes32",
        },
        {
          internalType: "bytes32",
          name: "_retailerLocation",
          type: "bytes32",
        },
      ],
      name: "receiveItemByRetailer",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_account",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "_profile",
          type: "uint256",
        },
      ],
      name: "registerAccount",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_farmID",
          type: "bytes32",
        },
        {
          internalType: "bytes32",
          name: "_farmName",
          type: "bytes32",
        },
        {
          internalType: "int32",
          name: "_farmArea",
          type: "int32",
        },
        {
          internalType: "int32",
          name: "_farmLat",
          type: "int32",
        },
        {
          internalType: "int32",
          name: "_farmLon",
          type: "int32",
        },
      ],
      name: "registerFarm",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_farmID",
          type: "bytes32",
        },
        {
          internalType: "int32",
          name: "_ndvi",
          type: "int32",
        },
        {
          internalType: "int32",
          name: "_gci",
          type: "int32",
        },
        {
          internalType: "int32",
          name: "_ndwi",
          type: "int32",
        },
        {
          internalType: "int32",
          name: "_infr",
          type: "int32",
        },
      ],
      name: "registerIdxs",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_farmID",
          type: "bytes32",
        },
        {
          internalType: "string",
          name: "_orgnFarmIpfsCID",
          type: "string",
        },
        {
          internalType: "string",
          name: "_orgnFarmImgFile",
          type: "string",
        },
      ],
      name: "registerIpfs",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_account",
          type: "address",
        },
      ],
      name: "removeAccount",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_itemID",
          type: "bytes32",
        },
      ],
      name: "sellItemByRetailer",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_itemID",
          type: "bytes32",
        },
      ],
      name: "shipItemByDistributor",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes32",
          name: "_itemID",
          type: "bytes32",
        },
      ],
      name: "shipItemByFarmer",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
};

export default SupplyChainArtifact;
