import {
  Button,
  Input,
  Flex,
  Heading,
  Grid,
  GridItem,
  Text,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
// import { useEffect, useState, useCallback } from "react";
import { useState } from "react";
import useSupplyChain from "../../../hooks/useSupplyChain";

const FormOwner = () => {
  const { library, account } = useWeb3React();
  const supplyChain = useSupplyChain();
  const toast = useToast();
  //Address Registration variables
  const [addrInReg, setAddrInReg] = useState("");
  const [profInReg, setProfInReg] = useState(0);
  const [addrInDel, setAddrInDel] = useState("");
  // const [profAcc, setProfAcc] = useState(0);
  // Control TXs variables
  const [txSent, setTxSent] = useState(false);
  // Farm Registration variables
  const [farmIDReg, setFarmIDReg] = useState("");
  const [farmNameReg, setFarmNameReg] = useState("");
  const [farmAreaReg, setFarmAreaReg] = useState("");
  const [farmLatReg, setFarmLatReg] = useState("");
  const [farmLonReg, setFarmLonReg] = useState("");
  // const [farmIpfsReg, setFarmIpfsReg] = useState("");
  // const [farmImgFileReg, setFarmImgFileReg] = useState("");
  const profileAccName = {
    0: "Unassigned",
    1: "Farmer",
    2: "Distributor",
    3: "Retailer",
    4: "DataManager",
    5: "Admin",
  };

  const registerAccount = async () => {
    if (addrInReg === "" || profInReg === 0) {
      toast({
        title: "Account Registration Error",
        description: "Verify data",
        status: "error",
      });
    } else {
      setTxSent(true);
      supplyChain.methods
        .registerAccount(addrInReg, profInReg)
        .send({ from: account })
        .on("error", () => {
          setTxSent(false);
          toast({
            title: "Accourt Registration - Error",
            description: "Error during registration",
            status: "error",
          });
        })
        .on("transactionHash", (txHash) => {
          toast({
            title: "Accourt Registration - Transaction sent",
            description: txHash,
            status: "info",
          });
        })
        .on("receipt", () => {
          setTxSent(false);
          toast({
            title: "Accourt Registration - Transaction validated",
            description: `The address ${addrInReg} was register as ${profileAccName[profInReg]}`,
            status: "success",
          });
        });
    }
  };

  const deleteAccount = async () => {
    if (addrInDel === "") {
      toast({
        title: "Delete Account - Error",
        description: "Verify data",
        status: "error",
      });
    } else {
      setTxSent(true);
      supplyChain.methods
        .removeAccount(addrInDel)
        .send({ from: account })
        .on("error", () => {
          setTxSent(false);
          toast({
            title: "Delete Account - Error",
            description: "Error during delete process",
            status: "error",
          });
        })
        .on("transactionHash", (txHash) => {
          toast({
            title: "Delete Account - Transaction sent",
            description: txHash,
            status: "info",
          });
        })
        .on("receipt", () => {
          setTxSent(false);
          toast({
            title: "Delete Account - Transaction validated",
            description: `The address ${addrInDel} was removed from Database`,
            status: "success",
          });
        });
    }
  };

  const registerFarm = async () => {
    if (
      farmIDReg === "" ||
      farmNameReg === "" ||
      farmAreaReg === "" ||
      farmLatReg === "" ||
      farmLonReg === ""
      // farmIpfsReg === "" ||
      // farmImgFileReg === ""
    ) {
      toast({
        title: "Farm Registration - Error",
        description: "Verify data",
        status: "error",
      });
    } else {
      setTxSent(true);
      supplyChain.methods
        .registerFarm(
          library.utils.padRight(library.utils.utf8ToHex(farmIDReg), 64),
          library.utils.padRight(library.utils.utf8ToHex(farmNameReg), 64),
          farmAreaReg,
          library.utils.toBN(farmLatReg),
          library.utils.toBN(farmLonReg)
        )
        .send({ from: account })
        .on("error", () => {
          setTxSent(false);
          toast({
            title: "Farm Registration - Error",
            description: "Error during registration process",
            status: "error",
          });
        })
        .on("transactionHash", (txHash) => {
          toast({
            title: "Farm Registration - Transaction sent",
            description: txHash,
            status: "info",
          });
        })
        .on("receipt", () => {
          setTxSent(false);
          toast({
            title: "Farm Registration - Transaction validated",
            description: `The farm ${farmIDReg} was successfully registered in the Database`,
            status: "success",
          });
        });
    }
  };
  return (
    <Flex
      flexDirection="column"
      alignItems={"center"}
      justifyContent="center"
      minWidth="80%"
    >
      <Flex
        flexDirection="column"
        minWidth="100%"
        alignItems="center"
        gap="3"
        mt="40px"
        mb="60px"
      >
        <Heading size="md" mb="20px" minWidth="100%">
          MANAGE ACCOUNTS
        </Heading>

        <Grid
          minWidth={"80%"}
          templateColumns={["90%", "300px 150px 200px"]}
          gap={6}
          justifyContent="center"
          alignItems="center"
        >
          <GridItem rowSpan="1" colSpan="3">
            <Text>Register Account</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Account"
              size="md"
              bg="white"
              // w={["100%", "200px"]}
              value={addrInReg}
              onChange={(event) => setAddrInReg(event.target.value)}
            />
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Select
              placeholder="Select profile"
              onChange={(event) => setProfInReg(event.target.value)}
            >
              <option value="1"> Farmer</option>
              <option value="2"> Distributor</option>
              <option value="3"> Retailer</option>
              <option value="4"> Data Manager</option>
              <option value="5"> Admin</option>
            </Select>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Button
              w={["100%", "100px"]}
              colorScheme="blue"
              size="md"
              type="submit"
              onClick={() => registerAccount()}
              isLoading={txSent}
              isDisabled={profInReg === "" || profInReg === 0 ? true : false}
            >
              Register
            </Button>
          </GridItem>
          <GridItem rowSpan="1" colSpan="3" mt="20px">
            <Text>Delete Account</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Account"
              size="md"
              bg="white"
              // w={["100%", "200px"]}
              value={addrInDel}
              onChange={(event) => setAddrInDel(event.target.value)}
            />
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Button
              w={["100%", "100px"]}
              colorScheme="red"
              size="md"
              // fontWeight="bold"
              type="submit"
              onClick={() => deleteAccount()}
              isLoading={txSent}
            >
              Delete
            </Button>
          </GridItem>
        </Grid>
      </Flex>
      <Flex
        flexDirection="column"
        minWidth="100%"
        alignItems="center"
        justifyContent="center"
        gap="3"
        mt="40px"
        mb="60px"
      >
        <Heading size="md" mb="20px" minWidth="100%">
          FARM REGISTRATION
        </Heading>
        <Grid
          minWidth={"80%"}
          templateColumns={["90%", "100px 300px"]}
          gap={6}
          justifyContent="center"
          alignItems="center"
        >
          <GridItem rowSpan="1" colSpan="1">
            <Text>ID:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="ID"
              size="md"
              bg="white"
              value={farmIDReg}
              onChange={(event) => setFarmIDReg(event.target.value)}
            />
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Text>Name:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Name"
              size="md"
              bg="white"
              value={farmNameReg}
              onChange={(event) => setFarmNameReg(event.target.value)}
            />
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Text>Area:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Area (ha)"
              size="md"
              bg="white"
              value={farmAreaReg}
              onChange={(event) => setFarmAreaReg(event.target.value)}
            />
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Text>Latitude:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Latitude"
              size="md"
              bg="white"
              value={farmLatReg}
              onChange={(event) => setFarmLatReg(event.target.value)}
            />
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Text>Longitude:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Longitude"
              size="md"
              bg="white"
              value={farmLonReg}
              onChange={(event) => setFarmLonReg(event.target.value)}
            />
          </GridItem>
          {/* <GridItem rowSpan="1" colSpan="1">
						<Text>Ipfs Index:</Text>
						</GridItem>
					<GridItem rowSpan="1" colSpan="1">
						<Input
							placeholder="Ipfs CID"
							size="md"
							bg="white"
							value={farmIpfsReg}
							onChange={(event) => setFarmIpfsReg(event.target.value)}
						/>
					</GridItem>
					<GridItem rowSpan="1" colSpan="1">
						<Text>Image Filename:</Text>
					</GridItem>
					<GridItem rowSpan="1" colSpan="1">
						<Input
						placeholder="Filename.jpg"
						size="md"
							bg="white"
							value={farmImgFileReg}
							onChange={(event) => setFarmImgFileReg(event.target.value)}
							/>
						</GridItem> */}
          <GridItem
            rowSpan="1"
            colSpan="2"
            justifyContent="center"
            alignItems="center"
            minWidth="100%"
            margin="auto"
          >
            <Flex justifyContent="center" alignItems="center">
              <Button
                w={["100%", "100px"]}
                colorScheme="blue"
                size="md"
                type="submit"
                onClick={() => registerFarm()}
                isLoading={txSent}
              >
                Register
              </Button>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default FormOwner;
