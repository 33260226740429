import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import SupplyChainArtifact from "../../config/web3/artifacts/SupplyChain";

const { address, abi } = SupplyChainArtifact;

const useSupplyChain = () => {
  const { active, library, chainId } = useWeb3React();

  const supplyChain = useMemo(() => {
    if (active) return new library.eth.Contract(abi, address[chainId]);
  }, [active, chainId, library?.eth?.Contract]);
  return supplyChain;
};

export default useSupplyChain;
