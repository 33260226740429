import { Route, Routes } from "react-router-dom";
import Start from "./views/home";
import Tracking from "./views/tracking";
import Maps from "./views/maps";
import MainLayout from "./layouts/main";
import Profile from "./views/profile";
import React,{ useState, useEffect, CSSProperties } from "react";
import { Box, StackItem } from "@chakra-ui/react";
import RingLoader from "react-spinners/RingLoader";

// import Guide from "./views/guide";
// import Contact from "./views/contact";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
function App() {
  const [loading, setLoading] = useState(false);
  useEffect(()=>{
    setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    },3000)
  },[])
  return (
    <div>
      {
        loading?
        <Box bgColor={"black"} width="100%" height={"100vh"} justifyContent="center" alignItems={"center"} display="flex">
          <RingLoader
        css={override}
        size={100}
        color="gold"
        loading={loading}
        />
        </Box>
        :
        <MainLayout>
          <Routes>
            <Route path="/" element={<Start />}></Route>
            <Route path="/tracking" element={<Tracking />}></Route>
            <Route path="/maps" element={<Maps />}></Route>
            <Route path="/profile" element={<Profile />}></Route>
          </Routes>
        </MainLayout>
      }
    </div>
  );
}

export default App;
