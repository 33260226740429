import {
  // Button,
  // Input,
  Flex,
  Box,
  Heading,
  // Grid,
  // GridItem,
  // Text,
  // Select,
  // useDisclosure,
  // useToast,
  // Alert,
  // AlertIcon,
  // AlertTitle,
  // AlertDescription,
} from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
// import Loading from "../../components/loading";
import AccessControl from "../../components/access-control";
import { useEffect, useState, useCallback } from "react";
import useSupplyChain from "../../hooks/useSupplyChain";
import FormUnknown from "../../components/profiles/addr-unknown";
import FormFarmer from "../../components/profiles/addr-farmer";
import FormDistributor from "../../components/profiles/addr-distributor";
import FormRetailer from "../../components/profiles/addr-retailer";
import FormDataManager from "../../components/profiles/addr-datamanager";
import FormOwner from "../../components/profiles/addr-owner";

const Profile = () => {
  const { active, account } = useWeb3React();
  const [profAcc, setProfAcc] = useState(0);
  //Address Registration variables
  // const [addrInReg, setAddrInReg] = useState("");
  // const [profInReg, setProfInReg] = useState("");
  // const [addrInDel, setAddrInDel] = useState("");
  // Control TXs variables
  // const [txAddrReg, setTxAddrReg] = useState(false);
  // const [txAddrDel, setTxAddrDel] = useState(false);
  // const [txFarmReg, setTxFarmReg] = useState(false);
  // Farm Registration variables
  // const [farmIDReg, setFarmIDReg] = useState("");
  // const [farmNameReg, setFarmNameReg] = useState("");
  // const [farmAreaReg, setFarmAreaReg] = useState("");
  // const [farmLatReg, setFarmLatReg] = useState("");
  // const [farmLonReg, setFarmLonReg] = useState("");
  // const [farmIpfsReg, setFarmIpfsReg] = useState("");
  // const [farmImgFileReg, setFarmImgFileReg] = useState("");
  // Item Registration variables
  // const [itemIDReg, setItemIDReg] = useState("");
  // const [itemNameReg, setItemNameReg] = useState("");
  // const [itemDescrReg, setItemDescrReg] = useState("");
  // const [itemFarmIDReg, setItemFarmIDReg] = useState("");
  // const [itemFarmNameReg, setItemFarmNameReg] = useState("");
  // const [itemFarmerAccReg, setItemFarmerAccReg] = useState("");

  const supplyChain = useSupplyChain();

  const profileAccName = {
    0: "Unassigned",
    1: "Farmer",
    2: "Distributor",
    3: "Retailer",
    4: "Data Manager",
    5: "Admin",
  };
  const update = useCallback(async () => {
    if (active) {
      // console.log(`Current account: ${account}`);
      const ownerAccount = await supplyChain.methods.owner().call();
      // console.log(`Owner account: ${ownerAccount}`);
      if (account === ownerAccount) {
        // console.log("Same accounts");
        setProfAcc(5);
      } else {
        // const profCase = 0;
        const profAccount = await supplyChain.methods
          .getAddrRole(account)
          .call();
        // console.log(profAccount);
        setProfAcc(profAccount);
      }
    } else {
      setProfAcc(-1);
    }
  }, [supplyChain, active, account]);

  useEffect(() => {
    update();
  }, [update]);

  const form = {
    0: <FormUnknown />,
    1: <FormFarmer />,
    2: <FormDistributor />,
    3: <FormRetailer />,
    4: <FormDataManager />,
    5: <FormOwner />,
  };

  if (profAcc === -1) {
    return <AccessControl />;
  } else {
    return (
      <Flex
        flexDirection="column"
        minWidth={["100%", "80%"]}
        alignItems={"center"}
        justifyContent="center"
        m="0px"
        p="0px"
        paddingTop="70px"
      >
        <Flex
          justifyContent="center"
          // alignItems={"center"}
          flexDirection={["column", "row"]}
          minWidth={["70%", "40%"]}
          gap="3"
          my="20px"
        >
          <Heading justifyContent="center" size="md" w="40%">
            PROFILE:
          </Heading>
          <Box size="md" bg="gray.200" w="40%" px="2">
            {profileAccName[profAcc]}
          </Box>
        </Flex>
        {form[profAcc]}
      </Flex>
    );
  }
};
export default Profile;
