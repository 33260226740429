import {
  Button,
  Flex,
  Input,
  Icon,
  Spacer,
  Image,
  // Heading,
  // Grid,
  // GridItem,
  // Text,
  // Box,
  Table,
  Thead,
  Tbody,
  // Tfoot,
  Tr,
  Th,
  Td,
  // TableCaption,
  TableContainer,
  Tooltip,
  // useToast,
} from "@chakra-ui/react";
// import { Field, Form, Formik } from "formik";

import { useWeb3React } from "@web3-react/core";
import { useState, useEffect } from "react";
import useSupplyChain from "../../hooks/useSupplyChain";
// import Map from "../../components/map";

const Maps = () => {
  const { active, library } = useWeb3React();
  const iniFarmID = localStorage.getItem("FarmID");
  const [farmID, setFarmID] = useState(iniFarmID);
  // const [idxNDVI, setIdxNDVI] = useState("");
  // const [idxGCI, setIdxGCI] = useState("");
  // const [idxNDWI, setIdxNDWI] = useState("");
  // const [idxINFR, setIdxINFR] = useState("");
  const colorGray = "gray.300";
  const [colorNDVI, setColorNDVI] = useState(colorGray);
  const [colorGCI, setColorGCI] = useState(colorGray);
  const [colorNDWI, setColorNDWI] = useState(colorGray);
  const [colorINFR, setColorINFR] = useState(colorGray);

  const [farmData, setFarmData] = useState({
    ndvi: "--.--",
    gci: "--.--",
    ndwi: "--.--",
    infr: "--.--",
    ipfsCID: "",
    imgFile: "",
  });
  const supplyChain = useSupplyChain();

  // const sleep = async (ms) => {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // };

  const getFarmData = async () => {
    console.log(farmID);
    const strData = {
      ndvi: "--.--",
      gci: "--.--",
      ndwi: "--.--",
      infr: "--.--",
      ipfsCID: "",
      imgFile: "",
    };
    if (supplyChain) {
      if (!(farmID.length === 0)) {
        const id = await library.utils.utf8ToHex(farmID);
        const data = await supplyChain.methods.getFarmData(id).call();
        const keys = Object.keys(strData);
        if (!data.farmName.startsWith("0x00000")) {
          strData.ndvi = data.ndvi;
          strData.gci = data.gci;
          strData.ndwi = data.ndwi;
          strData.infr = data.infr;
          strData.ipfsCID = data.farmIpfsCID;
          strData.imgFile = data.farmImgFile;
        }
        levelColor(keys[0], data.ndvi);
        levelColor(keys[1], data.gci);
        levelColor(keys[2], data.ndwi);
        levelColor(keys[3], data.infr);
        setFarmData(strData);
      } else {
        const keysStrData = Object.keys(strData);
        for (let i = 0; i < keysStrData.length - 2; i++) {
          levelColor(keysStrData[i], 0);
        }
      }
      localStorage.setItem("FarmID", "");
      setFarmData(strData);
    }
    // console.log(farmID_);
  };

  const CircleIcon = (props) => (
    <Icon viewBox="0 0 200 200" {...props}>
      <path
        fill="currentColor"
        d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
      />
    </Icon>
  );

  const levelColor = (idx, level) => {
    let colorLevel = "gray.300";
    if (level >= 66) {
      colorLevel = "green";
    } else if (level >= 33) {
      colorLevel = "yellow";
    } else if (level > 0) {
      colorLevel = "red.500";
    }
    if (idx === "ndvi") setColorNDVI(colorLevel);
    if (idx === "gci") setColorGCI(colorLevel);
    if (idx === "ndwi") setColorNDWI(colorLevel);
    if (idx === "infr") setColorINFR(colorLevel);
  };

  useEffect(() => {
    getFarmData();
  }, [supplyChain]);

  return (
    <Flex
      flexDirection="column"
      minWidth={["100%", "80%"]}
      alignItems={"center"}
      justifyContent="center"
      m="0px"
      p="0px"
    >
      <Flex
        flexDirection={["column", "row"]}
        minWidth={["70%", "40%"]}
        gap="3"
        my="40px"
        paddingTop="50px"
      >
        <Input
          placeholder="Farmer ID"
          size="md"
          bg="white"
          w={["100%", "300px"]}
          focusBorderColor="purple.300"
          value={farmID}
          onChange={(event) => setFarmID(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") getFarmData();
          }}
          _focus={{
            boxShadow: "2xl",
          }}
        />
        <Spacer />
        <Button
          w={["100%", "150px"]}
          colorScheme="purple"
          size="md"
          fontWeight="bold"
          type="submit"
          onClick={() => getFarmData()}
          isDisabled={!active ? true : false}
        >
          GET DATA
        </Button>
      </Flex>
      {/* <Form>
        <Input
          placeholder="Farmer ID"
          size="md"
          bg="white"
          w={["50%", "300px"]}
          focusBorderColor="purple.300"
          // {...field}
          onKeyDown={(event) => {
            if (event.key === "Enter") getFarmData();
          }}
          _focus={{
            boxShadow: "2xl",
          }}
        />
        <Button
          type="submit"
          w={["80%", "150px"]}
          colorScheme="purple"
          size="md"
          fontWeight="bold"
          onClick={() => getFarmData()}
          isDisabled={!active ? true : false}
        >
          GET DATA
        </Button>
      </Form> */}
      <TableContainer
        minW={["", "55%"]}
        p="6"
        border="1px"
        borderRadius="2xl"
        borderColor="gray.300"
      >
        <Table variant="simple" size="md">
          {/* <TableCaption>            
          </TableCaption> */}
          <Thead>
            <Tr>
              <Th fontSize="md">Description</Th>
              {/* <Th fontSize="md">Area</Th> */}
              <Th textAlign="center" fontSize="md">
                Value
              </Th>
              <Th textAlign="center" fontSize="md">
                Status
              </Th>
              <Th fontSize="md">Index</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td size="md">
                <Tooltip
                  label="Productivity index - It represents the amount of (ask Eduardo)"
                  aria-label="A tooltip"
                  bg="gray.100"
                  color="black"
                >
                  Productivity
                </Tooltip>
              </Td>
              <Td textAlign="center">{farmData.ndvi}</Td>
              <Td textAlign="center">
                <CircleIcon color={colorNDVI} />
              </Td>
              <Td>NDVI</Td>
            </Tr>
            <Tr>
              <Td>
                <Tooltip
                  label="Efficiency index - It represents the amount of (ask Eduardo)"
                  aria-label="A tooltip"
                  bg="gray.100"
                  color="black"
                >
                  Efficiency
                </Tooltip>
              </Td>
              <Td textAlign="center">{farmData.gci}</Td>
              <Td textAlign="center">
                <CircleIcon color={colorGCI} />
              </Td>
              <Td>GCI</Td>
            </Tr>
            <Tr>
              <Td>
                <Tooltip
                  label="Humidity index - It represents the amount of (ask Eduardo)"
                  aria-label="A tooltip"
                  bg="gray.100"
                  color="black"
                >
                  Humidity
                </Tooltip>
              </Td>
              <Td textAlign="center">{farmData.ndwi}</Td>
              <Td textAlign="center">
                <CircleIcon color={colorNDWI} />
              </Td>
              <Td>NDWI</Td>
            </Tr>
            <Tr>
              <Td>
                <Tooltip
                  label="Productivity index - It represents the amount of (ask Eduardo)"
                  aria-label="A tooltip"
                  bg="gray.100"
                  color="black"
                >
                  Infrared
                </Tooltip>
              </Td>
              <Td textAlign="center">{farmData.infr}</Td>
              <Td textAlign="center">
                <CircleIcon color={colorINFR} />
              </Td>
              <Td>INFR</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      {/* <Map /> */}
      <Flex
        // minWidth={["80%", "80%"]}
        maxW="60%"
        gap="3"
        my="0px"
        alignItems={"center"}
        justifyContent="center"
      >
        {farmData.ipfsCID !== "" && farmData.imgFile !== "" ? (
          <Image
            src={`https://${farmData.ipfsCID}.ipfs.w3s.link/${farmData.imgFile}`}
          />
        ) : (
          ""
        )}
      </Flex>
    </Flex>
  );
};
export default Maps;
