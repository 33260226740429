import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
  Heading,
  VStack,
  Spacer,
  Text,
  transition,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import NavBar from "./navBar";
import WalletData from "./wallet-data";
import React, {useState,useEffect} from "react";

const Links = [
  { name: "Overview", to: "/" },
  { name: "Tracking", to: "/tracking" },
  { name: "Maps", to: "/maps" },
  { name: "Profile", to: "/profile" },
];

function MainLayout({ children }) {
  const { isOpen, onOpen, onClose, } = useDisclosure();
  const [cambio,setCambio]=useState(false);
  const [bgColor,setBgColor]=useState({
    color:"transparent", opacity:0.5
  });
  const changeBg=()=>{
    //console.log(window.scrollY);
    // console.log(cambio);
    //console.log(window.location.hash)
    if (window.scrollY>=150){
      setCambio(true);
      let interval = setInterval(()=>{
        if(bgColor.opacity >= 1){
          clearInterval(interval);
        }
        setBgColor(prevState =>({
          color:"gray.200",
          opacity: Math.min(prevState.opacity+0.1,1)
        }));
      },200);
    }
    else{
      setCambio(false);
      setBgColor(({
        color:"transparent",
        opacity: 0.5
      }));
    }
  };
  window.addEventListener("scroll",changeBg);
  return (
    <VStack
      minH="100vH"
      direction="column"
      as="header"
      position="center"
      w="100%"
    >
      <Box
        position={"fixed"}
        mx="auto"
        maxW={"7x1"}
        width="100%"
        bg={cambio ? bgColor.color : "transparent"} //{useColorModeValue("gray.200")} //black
        opacity={cambio ? bgColor.opacity : "1"}
        px={4}
        zIndex={999}
      >
        <Flex
          as="header"
          position="static"
          w="100%"
          bg="transparent" //black
          color={cambio ? "black": "black"} //gold
          minH={"60px"}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={2}
          borderStyle={"Solid"}
          borderColor={useColorModeValue("#bb923c")} //#bb923c
          alignItems={"left"}
          justifyContent={"space-between"}
        >
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            arial-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen} />
          <HStack spacing={8} alignItems={"center"}>
            <Flex alignItems={"center"}>
              <a href="https://goldensparrow.io/">
                <Image src="./logos/logo_sparrow.png" width="60px" />
                <Heading size="md" color="gold" mt={0.2} ml={1}></Heading>
              </a>
            </Flex>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
              zIndex={9999}
            >
              {Links.map(({ name, to }) => (
                <NavBar key={name} to={to}>
                  {name}
                </NavBar>
              ))}
            </HStack>
          </HStack>
          <WalletData />
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {Links.map(({ name, to }) => (
                <NavBar key={name} to={to}>
                  {name}
                </NavBar>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
      <Box mx={0} flex={1} p={0} maxW={"7x1"} width={"100%"} paddingTop="-20px"
      //borderColor="green"  border={"solid"} 
      position="top" // position top
      >
        {children}
      </Box> 
      <Box
        h="15vh"
        w="full"
        //gap="3" 
        justifyContent="center"
        alignItems={"center"}
        bgColor="black"
        position="static"
        border="5px"
        borderStyle={"Solid"}
        borderTopColor={"#bb923c"}
      >
        <Text
          color={"#bb923c"}
          padding="20px"
          position="static"
          fontSize={"2vh"}
          align="center">
          Golden Sparrow2021®
          <Spacer></Spacer>
          All rights reserved.
        </Text>
      </Box>
    </VStack>
  );
}

export default MainLayout;
