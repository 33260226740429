import {
  Flex,
  Button,
  // Tag,
  // TagLabel,
  // Badge,
  // TagCloseButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Highlight,
} from "@chakra-ui/react";
import { CopyIcon, CloseIcon } from "@chakra-ui/icons";

// import { Link } from "react-router-dom";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { connector } from "../../../config/web3";
// import { useCallback, useEffect, useState, useRef } from "react";
import { useCallback, useEffect, useRef } from "react";
import useTruncatedAddress from "../../../hooks/useTruncatedAddress";

const WalletData = () => {
  const { active, activate, deactivate, account, error } = useWeb3React();

  const isUnsupportedChain = error instanceof UnsupportedChainIdError;

  const connect = useCallback(() => {
    activate(connector);
    localStorage.setItem("prevConnected", "true");
  }, [activate]);

  const disconnect = () => {
    deactivate();
    localStorage.removeItem("prevConnected");
  };

  useEffect(() => {
    if (localStorage.getItem("prevConnected") === "true") connect();
  }, [connect]);

  const truncatedAddress = useTruncatedAddress(account);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  return (
    <Flex alignItems={"center"}>
      {active ? (
        <>
          {isOpen ? (
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Account
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    <Highlight
                      query={truncatedAddress}
                      styles={{ px: "1", py: "1", bg: "orange.100" }}
                    >
                      {truncatedAddress}
                    </Highlight>
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button
                      leftIcon={<CopyIcon />}
                      onClick={() => {
                        navigator.clipboard.writeText(account);
                        onClose();
                      }}
                    >
                      Copy Address
                    </Button>
                    <Button
                      colorScheme="orange"
                      leftIcon={<CloseIcon w="3" h="3" />}
                      onClick={() => {
                        disconnect();
                        onClose();
                      }}
                      ml={3}
                    >
                      Disconnect
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          ) : null}
          <Button height="30px" colorScheme={"blue"} size="md" onClick={onOpen}>
            {truncatedAddress}
          </Button>
        </>
      ) : (
        <Button
          height="30px"
          variant={"solid"}
          colorScheme={"blue"}
          size={"md"}
          onClick={connect}
          disabled={isUnsupportedChain}
        >
          {isUnsupportedChain ? "Network not supported" : "Connect Wallet"}
        </Button>
      )}
    </Flex>
  );
};

export default WalletData;
