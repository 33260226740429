import {
  Button,
  Flex,
  Input,
  Spacer,
  Heading,
  Grid,
  GridItem,
  Text,
  // Box,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Link,
  Icon,
} from "@chakra-ui/react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useWeb3React } from "@web3-react/core";
// import { useSupplyChainData } from "../../hooks/useSupplyChainData";
import useSupplyChain from "../../hooks/useSupplyChain";
// import { useCallback, useEffect, useState, useRef } from "react";
import { useState } from "react";
// import RequestAccess from "../../components/request-access";

const Tracking = () => {
  const { active, library } = useWeb3React();
  const [itemIDText, setItemIDText] = useState("");
  const [itemData, setItemData] = useState({
    itemID: "",
    itemName: "",
    description: "",
    orgnFarmName: "",
    orgnFarmLat: "",
    orgnFarmLon: "",
    orgnFarmID: "",
    orgnFarmerAcc: "",
    orgnImgIpfsLink: "",
    dataDistributor: { account: "", location: "", name: "" },
    dataRetailer: { account: "", location: "", name: "" },
    state: "",
  });
  const [slideValue, setSlideValue] = useState(0);
  const slideColor = { empty: "gray.300", filled: "green.400" };
  const [slideTrackColor, setSlideTrackColor] = useState(slideColor.empty);
  const supplyChain = useSupplyChain();

  const updateData = async () => {
    const strData = {
      itemID: "",
      itemName: "",
      description: "",
      orgnFarmName: "",
      orgnFarmLat: "",
      orgnFarmLon: "",
      orgnFarmID: "",
      orgnFarmerAcc: "",
      orgnImgIpfsLink: "",
      dataDistributor: { account: "", location: "", name: "" },
      dataRetailer: { account: "", location: "", name: "" },
      state: "",
    };
    const itemStates = [
      "Unassigned",
      "Harvested by farmer",
      "Processed by farmer",
      "Packed by farmer",
      "Shipped by farmer",
      "Received by distributor",
      "Packed by distributor",
      "Shipped by distributor",
      "Received by retailer",
      "Processed by retailer",
      "Purchased by customer",
    ];
    if (supplyChain) {
      if (!(itemIDText.length === 0)) {
        const id = await library.utils.utf8ToHex(itemIDText);
        let data = await supplyChain.methods.getItemData(id).call();
        // console.log(await library.utils.hexToUtf8(data.dataDistributor.account));
        // for (let i = 0; i < Object.keys(data).length; i++) {
        //   delete data[i];
        // }
        // console.log("data:");
        // console.log(data);

        if (!(data.state === "0")) {
          strData.itemID = itemIDText;
          strData.itemName = await library.utils.hexToUtf8(data.itemName);
          strData.description = await library.utils.hexToUtf8(data.description);
          strData.orgnFarmID = await library.utils.hexToUtf8(data.orgnFarmID);
          strData.orgnFarmName = await library.utils.hexToUtf8(
            data.orgnFarmName
          );
          strData.orgnFarmerAcc = data.farmerAcc;

          data.dataDistributor.account.startsWith("0x00000")
            ? (strData.dataDistributor.account = "")
            : (strData.dataDistributor.account = data.dataDistributor.account);

          strData.dataDistributor.location = await library.utils.hexToUtf8(
            data.dataDistributor.location
          );
          strData.dataDistributor.name = await library.utils.hexToUtf8(
            data.dataDistributor.name
          );

          data.dataRetailer.account.startsWith("0x00000")
            ? (strData.dataRetailer.account = "")
            : (strData.dataRetailer.account = data.dataRetailer.account);

          strData.dataRetailer.location = await library.utils.hexToUtf8(
            data.dataRetailer.location
          );
          strData.dataRetailer.name = await library.utils.hexToUtf8(
            data.dataRetailer.name
          );
          strData.state = itemStates[data.state];

          // Work with Farm data
          // console.log(farmData);

          const farmData = await supplyChain.methods
            .getFarmData(data.orgnFarmID)
            .call();

          if (
            !(farmData.farmIpfsCID === "") ||
            !(farmData.farmImgFile === "")
          ) {
            strData.orgnImgIpfsLink = `https://${farmData.farmIpfsCID}.ipfs.w3s.link/${farmData.farmImgFile}`;
          } else {
            strData.orgnImgIpfsLink = "";
          }
        }
        // setItemData(strData);
      }
      // else setItemData(strData);
      localStorage.setItem("FarmID", strData.orgnFarmID);
      setItemData(strData);

      if (!(strData.dataRetailer.name.length === 0)) {
        setSlideValue(100);
        setSlideTrackColor(slideColor.filled);
      } else if (!(strData.dataDistributor.name.length === 0)) {
        setSlideValue(57);
        setSlideTrackColor(slideColor.filled);
      } else if (!(strData.orgnFarmName.length === 0)) {
        setSlideValue(0);
        setSlideTrackColor(slideColor.filled);
      } else {
        setSlideValue(0);
        setSlideTrackColor(slideColor.empty);
      }
    }
  };

  return (
    <Flex
      flexDirection="column"
      minWidth={["100%", "80%"]}
      alignItems={"center"}
      justifyContent="center"
      bgColor={"gree-blue"}
      m="0px"
      p="0px"
      paddingTop="50px"
    >
      <Flex
        flexDirection={["column", "row"]}
        minWidth={["70%", "40%"]}
        gap="3"
        my="40px"
      >
        <Input
          placeholder="Track code"
          size="md"
          bg="white"
          w={["100%", "300px"]}
          focusBorderColor="purple.300"
          value={itemIDText}
          onChange={(event) => setItemIDText(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") updateData();
          }}
          _focus={{
            boxShadow: "2xl",
          }}
        />
        <Spacer />
        <Button
          w={["100%", "150px"]}
          colorScheme="purple"
          size="md"
          fontWeight="bold"
          type="submit"
          onClick={() => updateData()}
          isDisabled={!active ? true : false}
        >
          TRACK
        </Button>
      </Flex>
      <Flex flexDirection={"column"} minWidth="100%" alignItems="center" mb="6">
        <Heading size="md" mb="24px" minWidth={["80%", "60%"]}>
          PRODUCT
        </Heading>
        <Grid
          minWidth={"80%"}
          templateColumns={["90%", "200px 300px"]}
          gap={5}
          justifyContent="center"
          alignItems="center"
        >
          <GridItem rowSpan="1" colSpan="1">
            <Text>ID:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input isReadOnly="True" value={itemData.itemID} />
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Text>Name:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input isReadOnly="True" value={itemData.itemName} />
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Text>Description:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input isReadOnly="True" value={itemData.description} />
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Text>Status:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input isReadOnly="True" value={itemData.state} />
          </GridItem>
        </Grid>
      </Flex>
      <Flex minWidth={["100%", "45%"]} gap="3" my="40px">
        <Grid
          gap={3}
          justifyContent="center"
          alignItems="center"
          w={"100%"}
          templateColumns={["50px 250px", "50px 150px 250px"]}
        >
          <GridItem
            rowSpan={[31, 17]}
            colSpan={1}
            // bg="gray"
            h="100%"
            // w="6px"
            // justifyContent="top"
            alignItems="top"
          >
            <Slider
              value={slideValue}
              mt="12px"
              h="66%"
              isReversed="true"
              orientation="vertical"
              isReadOnly="true"
            >
              <SliderTrack bg={slideColor.empty}>
                <SliderFilledTrack bg={slideColor.filled} />
              </SliderTrack>
              <SliderThumb bg={slideTrackColor} boxSize={4}>
                {/* <Box color={slideTrackColor} as={StarIcon} /> */}
              </SliderThumb>
            </Slider>
          </GridItem>
          <GridItem rowSpan={1} colSpan={[1, 2]}>
            <Heading size="md" mb="24px">
              FARMER
            </Heading>
          </GridItem>
          <GridItem pl={["0", "5"]}>
            <Text>Name:</Text>
          </GridItem>
          <GridItem>
            <Input isReadOnly="True" value={itemData.orgnFarmName} />
          </GridItem>
          <GridItem pl={["0", "5"]}>
            <Text>Account:</Text>
          </GridItem>
          <GridItem>
            <Input isReadOnly="True" value={itemData.orgnFarmerAcc} />
          </GridItem>
          {/* <GridItem pl={["0", "5"]}>
            <Text>Latitude:</Text>
          </GridItem>
          <GridItem>
            <Input isReadOnly="True" value={itemData.orgnFarmLat} />
          </GridItem>
          <GridItem pl={["0", "5"]}>
            <Text>Longitude:</Text>
          </GridItem>
          <GridItem>
            <Input isReadOnly="True" value={itemData.orgnFarmLon} />
          </GridItem> */}
          <GridItem pl={["0", "5"]}>
            <Text>Image Link:</Text>
          </GridItem>
          <GridItem>
            {/* <Input isReadOnly="True" value={itemData.orgnImgIpfsLink} /> */}

            {itemData.orgnImgIpfsLink === "" ? (
              <>
                <Link px="4" color="gray.400">
                  IPFS LINK
                </Link>
                <Link px="4" color="gray.400">
                  MAP <Icon as={FaMapMarkerAlt} boxSize={4} mt="1" />
                </Link>
              </>
            ) : (
              <>
                <Link px="4" href={itemData.orgnImgIpfsLink} isExternal>
                  IPFS LINK
                </Link>
                <Link px="4" href="#/maps" isExternal>
                  MAP <Icon as={FaMapMarkerAlt} boxSize={4} />
                </Link>
              </>
            )}
          </GridItem>

          <GridItem rowSpan={1} colSpan={[1, 2]}>
            <Heading size="md" mb="24px" mt="40px">
              DISTRIBUTOR
            </Heading>
          </GridItem>
          <GridItem pl={["0", "5"]}>
            <Text>Name:</Text>
          </GridItem>
          <GridItem>
            <Input isReadOnly="True" value={itemData.dataDistributor.name} />
          </GridItem>
          <GridItem pl={["0", "5"]}>
            <Text>Account:</Text>
          </GridItem>
          <GridItem>
            <Input isReadOnly="True" value={itemData.dataDistributor.account} />
          </GridItem>
          <GridItem pl={["0", "5"]}>
            <Text>Location:</Text>
          </GridItem>
          <GridItem>
            <Input
              isReadOnly="True"
              value={itemData.dataDistributor.location}
            />
          </GridItem>

          <GridItem rowSpan={1} colSpan={[1, 2]}>
            <Heading size="md" mb="24px" mt="40px">
              RETAILER
            </Heading>
          </GridItem>
          <GridItem pl={["0", "5"]}>
            <Text>Name:</Text>
          </GridItem>
          <GridItem>
            <Input isReadOnly="True" value={itemData.dataRetailer.name} />
          </GridItem>
          <GridItem pl={["0", "5"]}>
            <Text>Account:</Text>
          </GridItem>
          <GridItem>
            <Input isReadOnly="True" value={itemData.dataRetailer.account} />
          </GridItem>
          <GridItem pl={["0", "5"]}>
            <Text>Location:</Text>
          </GridItem>
          <GridItem>
            <Input isReadOnly="True" value={itemData.dataRetailer.location} />
          </GridItem>
        </Grid>
      </Flex>
    </Flex>
  );
};
export default Tracking;
