import {
  Button,
  Input,
  Flex,
  Heading,
  Grid,
  GridItem,
  Text,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState, useCallback } from "react";
import useSupplyChain from "../../../hooks/useSupplyChain";

const FormFarmer = () => {
  const { library, account } = useWeb3React();
  const [itemIDReg, setItemIDReg] = useState("");
  const [itemNameReg, setItemNameReg] = useState("");
  const [itemDescrReg, setItemDescrReg] = useState("");
  const [itemFarmIDReg, setItemFarmIDReg] = useState("");
  const [itemFarmNameReg, setItemFarmNameReg] = useState("");
  const [farmList, setFarmList] = useState([]);
  const [txItemReg, setTxItemReg] = useState(false);
  const [itemIDProcess, setItemIDProcess] = useState("");
  const [itemIDPack, setItemIDPack] = useState("");
  const [itemIDShip, setItemIDShip] = useState("");
  const supplyChain = useSupplyChain();
  const toast = useToast();

  const getFarmList = useCallback(async () => {
    const list = await supplyChain.methods.getFarmsArr().call();
    let newList = [];
    list.map((element) => newList.push(library.utils.hexToUtf8(element)));
    setFarmList(newList);
  }, [supplyChain, library?.utils]);

  const getFarmName = async (farmID) => {
    let data = await supplyChain.methods
      .getFarmData(library.utils.utf8ToHex(farmID))
      .call();
    setItemFarmNameReg(library.utils.hexToUtf8(data.farmName));
  };

  useEffect(() => {
    getFarmList();
  }, [getFarmList]);

  const harvestItem = async () => {
    if (
      itemIDReg === "" ||
      itemNameReg === "" ||
      itemDescrReg === "" ||
      itemFarmIDReg === "" ||
      itemFarmNameReg === ""
    ) {
      toast({
        title: "Harvest Item Registration - Error",
        description: "Verify data",
        status: "error",
      });
    } else {
      setTxItemReg(true);
      supplyChain.methods
        .harvestItemByFarmer(
          library.utils.padRight(library.utils.utf8ToHex(itemIDReg), 64),
          library.utils.padRight(library.utils.utf8ToHex(itemNameReg), 64),
          library.utils.padRight(library.utils.utf8ToHex(itemDescrReg), 64),
          library.utils.padRight(library.utils.utf8ToHex(itemFarmIDReg), 64),
          library.utils.padRight(library.utils.utf8ToHex(itemFarmNameReg), 64)
        )
        .send({ from: account })
        .on("error", () => {
          setTxItemReg(false);
          toast({
            title: "Harvest Item Registration - Error",
            description: "Error during registration process",
            status: "error",
          });
        })
        .on("transactionHash", (txHash) => {
          toast({
            title: "Harvest Item Registration - Transaction sent",
            description: txHash,
            status: "info",
          });
        })
        .on("receipt", () => {
          setTxItemReg(false);
          toast({
            title: "Harvest Item Registration - Transaction validated",
            description: `The Item ${itemIDReg} was successfully registered as Harvested`,
            status: "success",
          });
        });
    }
  };

  const processItem = async () => {
    if (itemIDProcess === "") {
      toast({
        title: "Process Item Registration - Error",
        description: "Verify ID",
        status: "error",
      });
    } else {
      setTxItemReg(true);
      supplyChain.methods
        .processItemByFarmer(
          library.utils.padRight(library.utils.utf8ToHex(itemIDProcess), 64)
        )
        .send({ from: account })
        .on("error", () => {
          setTxItemReg(false);
          toast({
            title: "Process Item Registration - Error",
            description: "Error during registration process",
            status: "error",
          });
        })
        .on("transactionHash", (txHash) => {
          toast({
            title: "Process Item Registration - Transaction sent",
            description: txHash,
            status: "info",
          });
        })
        .on("receipt", () => {
          setTxItemReg(false);
          toast({
            title: "Process Item Registration - Transaction validated",
            description: `The Item ${itemIDProcess} was successfully registered as Harvested`,
            status: "success",
          });
        });
    }
  };

  const packItem = async () => {
    if (itemIDPack === "") {
      toast({
        title: "Pack Item Registration - Error",
        description: "Verify ID",
        status: "error",
      });
    } else {
      setTxItemReg(true);
      supplyChain.methods
        .packItemByFarmer(
          library.utils.padRight(library.utils.utf8ToHex(itemIDPack), 64)
        )
        .send({ from: account })
        .on("error", () => {
          setTxItemReg(false);
          toast({
            title: "Pack Item Registration - Error",
            description: "Error during registration process",
            status: "error",
          });
        })
        .on("transactionHash", (txHash) => {
          toast({
            title: "Pack Item Registration - Transaction sent",
            description: txHash,
            status: "info",
          });
        })
        .on("receipt", () => {
          setTxItemReg(false);
          toast({
            title: "Pack Item Registration - Transaction validated",
            description: `The Item ${itemIDPack} was successfully registered as Harvested`,
            status: "success",
          });
        });
    }
  };

  const shipItem = async () => {
    if (itemIDShip === "") {
      toast({
        title: "Ship Item Registration - Error",
        description: "Verify ID",
        status: "error",
      });
    } else {
      setTxItemReg(true);
      supplyChain.methods
        .shipItemByFarmer(
          library.utils.padRight(library.utils.utf8ToHex(itemIDShip), 64)
        )
        .send({ from: account })
        .on("error", () => {
          setTxItemReg(false);
          toast({
            title: "Ship Item Registration - Error",
            description: "Error during registration process",
            status: "error",
          });
        })
        .on("transactionHash", (txHash) => {
          toast({
            title: "Ship Item Registration - Transaction sent",
            description: txHash,
            status: "info",
          });
        })
        .on("receipt", () => {
          setTxItemReg(false);
          toast({
            title: "Ship Item Registration - Transaction validated",
            description: `The Item ${itemIDShip} was successfully registered as Harvested`,
            status: "success",
          });
        });
    }
  };

  return (
    <Flex
      flexDirection="column"
      alignItems={"center"}
      justifyContent="center"
      minWidth="80%"
    >
      {/* Harvest Item form */}
      <Flex
        flexDirection="column"
        minWidth="100%"
        alignItems="center"
        gap="3"
        mt="40px"
        mb="60px"
      >
        <Heading size="md" mb="20px" minWidth="100%">
          HARVEST ITEM
        </Heading>
        <Grid
          minWidth={"80%"}
          templateColumns={["90%", "100px 300px"]}
          gap={6}
          justifyContent="center"
          alignItems="center"
        >
          <GridItem rowSpan="1" colSpan="1">
            <Text>ID:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Item ID"
              size="md"
              bg="white"
              value={itemIDReg}
              onChange={(event) => setItemIDReg(event.target.value)}
            />
          </GridItem>

          <GridItem rowSpan="1" colSpan="1">
            <Text>Name:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Item name"
              size="md"
              bg="white"
              value={itemNameReg}
              onChange={(event) => setItemNameReg(event.target.value)}
            />
          </GridItem>

          <GridItem rowSpan="1" colSpan="1">
            <Text>Description:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Item description"
              size="md"
              bg="white"
              value={itemDescrReg}
              onChange={(event) => setItemDescrReg(event.target.value)}
            />
          </GridItem>

          <GridItem rowSpan="1" colSpan="1">
            <Text>Farm ID:</Text>
          </GridItem>
          <Select
            placeholder="Select Farm ID"
            onChange={(event) => {
              setItemFarmIDReg(event.target.value);
              getFarmName(event.target.value);
            }}
          >
            {farmList.map((farm) => (
              <option value={farm} key={farm}>
                {farm}
              </option>
            ))}
          </Select>

          <GridItem rowSpan="1" colSpan="1">
            <Text>Farm Name:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              size="md"
              bg="white"
              isReadOnly="True"
              value={itemFarmNameReg}
            />
          </GridItem>

          <GridItem
            rowSpan="1"
            colSpan="2"
            justifyContent="center"
            alignItems="center"
            minWidth="100%"
            margin="auto"
          >
            <Flex justifyContent="center" alignItems="center">
              <Button
                w={["100%", "100px"]}
                colorScheme="blue"
                size="md"
                type="submit"
                onClick={() => harvestItem()}
                isLoading={txItemReg}
              >
                Register
              </Button>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
      {/* Process Item form */}
      <Flex
        flexDirection="column"
        minWidth="100%"
        alignItems="center"
        gap="3"
        mt="40px"
        mb="60px"
      >
        <Heading size="md" mb="20px" minWidth="100%">
          PROCESS ITEM
        </Heading>
        <Grid
          minWidth={"80%"}
          templateColumns={["90%", "100px 300px"]}
          gap={6}
          justifyContent="center"
          alignItems="center"
        >
          <GridItem rowSpan="1" colSpan="1">
            <Text>ID:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Item ID"
              size="md"
              bg="white"
              value={itemIDProcess}
              onChange={(event) => setItemIDProcess(event.target.value)}
            />
          </GridItem>
          <GridItem
            rowSpan="1"
            colSpan="2"
            justifyContent="center"
            alignItems="center"
            minWidth="100%"
            margin="auto"
          >
            <Flex justifyContent="center" alignItems="center">
              <Button
                w={["100%", "100px"]}
                colorScheme="blue"
                size="md"
                type="submit"
                onClick={() => processItem()}
                isLoading={txItemReg}
              >
                Register
              </Button>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
      {/* Pack Item form */}
      <Flex
        flexDirection="column"
        minWidth="100%"
        alignItems="center"
        gap="3"
        mt="40px"
        mb="60px"
      >
        <Heading size="md" mb="20px" minWidth="100%">
          PACK ITEM
        </Heading>
        <Grid
          minWidth={"80%"}
          templateColumns={["90%", "100px 300px"]}
          gap={6}
          justifyContent="center"
          alignItems="center"
        >
          <GridItem rowSpan="1" colSpan="1">
            <Text>ID:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Item ID"
              size="md"
              bg="white"
              value={itemIDPack}
              onChange={(event) => setItemIDPack(event.target.value)}
            />
          </GridItem>
          <GridItem
            rowSpan="1"
            colSpan="2"
            justifyContent="center"
            alignItems="center"
            minWidth="100%"
            margin="auto"
          >
            <Flex justifyContent="center" alignItems="center">
              <Button
                w={["100%", "100px"]}
                colorScheme="blue"
                size="md"
                type="submit"
                onClick={() => packItem()}
                isLoading={txItemReg}
              >
                Register
              </Button>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
      {/* Ship Item form */}
      <Flex
        flexDirection="column"
        minWidth="100%"
        alignItems="center"
        gap="3"
        mt="40px"
        mb="60px"
      >
        <Heading size="md" mb="20px" minWidth="100%">
          SHIP ITEM
        </Heading>
        <Grid
          minWidth={"80%"}
          templateColumns={["90%", "100px 300px"]}
          gap={6}
          justifyContent="center"
          alignItems="center"
        >
          <GridItem rowSpan="1" colSpan="1">
            <Text>ID:</Text>
          </GridItem>
          <GridItem rowSpan="1" colSpan="1">
            <Input
              placeholder="Item ID"
              size="md"
              bg="white"
              value={itemIDShip}
              onChange={(event) => setItemIDShip(event.target.value)}
            />
          </GridItem>
          <GridItem
            rowSpan="1"
            colSpan="2"
            justifyContent="center"
            alignItems="center"
            minWidth="100%"
            margin="auto"
          >
            <Flex justifyContent="center" alignItems="center">
              <Button
                w={["100%", "100px"]}
                colorScheme="blue"
                size="md"
                type="submit"
                onClick={() => shipItem()}
                isLoading={txItemReg}
              >
                Register
              </Button>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default FormFarmer;
